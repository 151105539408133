<template>
  <li
    v-expose="{
      id: '1-8-4-14',
      once: false,
      data: commonAnalysis
    }"
    v-tap="{
      id: '1-8-4-15',
      data: commonAnalysis
    }"
    class="cart-promotion__list-item"
  >
    <div
      class="list-item__img"
    >
      <PromotionImg
        :item="item"
        :class="{'cart-item__img-sold-out': isSoldOut}"
      />
      <div 
        v-if="isSoldOut"
        class="cart-item__stock-out"
      >
        <span>{{ language.SHEIN_KEY_PWA_14987 }}</span>
      </div>
      <div
        v-else-if="[2, 4, 28].includes(+promotionItem.type_id)"
        :class="[
          'cart-item__free',
          {
            'cart-item__free_club': isClubGift
          }
        ]"
      >
        <span>
          {{ language.SHEIN_KEY_PWA_23895 }}
        </span>
      </div>
    </div>
    <div class="cart-promotion__item-right">
      <div>
        <p class="cart-promotion__item-name">
          {{ item.goods_name }}
        </p>
        <div :class="['cart-promotion__item-price', priceContentClasses]">
          <div class="price-content">
            <div class="price-sale">
              <Icon
                v-if="isClubGift"
                name="sui_icon_club_logo_fill_15px"
                size="15px"
                color="#873C00"
              />
              <span
                class="price-amount"
                :style="{
                  '--color': unitPrice.color
                }"
              >
                {{ unitPrice.price.amountWithSymbol }}
              </span>
              <span
                v-if="originalPrice"
                class="original-del"
              >
                <del>{{ originalPrice.price.amountWithSymbol }}</del>
                <span v-if="originalPrice.description">&nbsp;{{ originalPrice.description }}</span>
                <CartItemPricePopover
                  v-if="originalPrice.tip && originalPrice.tip.desc"
                  ref="popover"
                  :content="originalPrice.tip.desc"
                  listenTarget=".j-promotion-product__list"
                  @toggle="showPop = $event"
                />
              </span>
            </div>
          </div>
          <span class="del-wrap">
            <span
              v-if="lowestPrice"
              class="del"
            >
              <del>{{ lowestPrice.price.amountWithSymbol }}</del>
              <span v-if="lowestPrice.description">&nbsp;{{ lowestPrice.description }}</span>
              <CartItemPricePopover
                v-if="lowestPrice.tip && lowestPrice.tip.desc"
                ref="popover"
                :content="lowestPrice.tip.desc"
                listenTarget=".j-promotion-product__list"
                @toggle="showPop = $event"
              />
            </span>
            <span
              v-if="isShowSavePrice"
              class="save-amount"
            >
              <span><svg
                class="save-amount-icon"
                width="6"
                height="7"
                viewBox="0 0 6 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 0.2C1.5 0.0895431 1.58954 0 1.7 0H4.3C4.41046 0 4.5 0.0895431 4.5 0.2V3.8C4.5 3.91046 4.58954 4 4.7 4H5.51716C5.69534 4 5.78457 4.21543 5.65858 4.34142L3.14142 6.85858C3.06332 6.93668 2.93668 6.93668 2.85858 6.85858L0.341421 4.34142C0.215428 4.21543 0.304662 4 0.482843 4H1.3C1.41046 4 1.5 3.91046 1.5 3.8V0.2Z"
                  fill="#FA6338"
                />
              </svg>
              </span>
              <span>{{ item.discount_price.amountWithSymbol }}</span>
            </span>
          </span>
        </div>
        <div
          v-if="showQuickShip || (item.brand_type == 1 && [13, 22].includes(+promotionItem.type_id)) || evoluTag"
          :class="[
            'promotion-quick-ship',
            {
              'promotion-quick-ship_disabled': isDisabled || isSoldOut,
            }
          ]"
        >
          <SFSTag
            v-if="showSfsTag"
            class="promotion-quick-ship__label"
            :language="language"
            :is-new-cartrow-vision="isNewCartrowVision"
            :showExtendText="CartOrderSFSVisual.visual_benefit_point"
          />
          <QuickShipLabel
            v-if="showQuickShip"
            class="promotion-quick-ship__label"
            :info="quickShipLabel"
          />
          <!-- evoluSHEIN -->
          <EvoluTag
            v-if="evoluTag"
            class="promotion-quick-ship__label"
            :info="evoluTag"
          />
          <span
            v-if="item.brand_type == 1 && [13, 22].includes(+promotionItem.type_id) "
            v-expose="{
              id: '1-8-5-9',
              data: {
                productid: item.goods_id,
              }
            }"
          >
            <s-label
              class="promotion-quick-ship__label"
            >
              {{ language.SHEIN_KEY_PWA_18981 }}
            </s-label>
          </span>
        </div>
        <ActTag
          v-if="isShowActTag"
          class="cart-promotion__behavior"
          :info="actTag"
        />
      </div>
      <div class="cart-promotion__item-footer">
        <div
          class="cart-promotion__item-comments"
          :class="[
            'cart-promotion__item-comments',
            {
              'cart-promotion__item-comments_hide': !isShowComment
            }
          ]"
        >
          <span
            class="stars"
            v-html="GB_SHEIN_counting_star(item.comment_rank_average || 0, 15)"
          >
          </span>
          <span class="num">({{ item.comment_num_show || 0 }})</span>
        </div>
        <div
          v-tap="{
            id: '1-8-4-11',
            data: commonAnalysis
          }"
          class="pick-btn"
          :class="{'pick-btn-disabled': isDisabled || isSoldOut}"
          @click.stop.prevent="isDisabled ? handleBtnClick() : handleOpenQuickAdd()"
        >
          <span class="pick-btn-text">{{ isPicked ? language.SHEIN_KEY_PWA_15831 : language.SHEIN_KEY_PWA_15830 }}</span>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter'
import { scrollToCartItem } from 'public/src/pages/cart_v2/utils/index.js'
import PromotionImg from './PromotionImg.vue'
import ActTag from './ActTag.vue'
import QuickShipLabel from './QuickShipLabel.vue'
import { getImageRatio } from 'public/src/pages/goods_detail_v2/utils/common.js'
import AddOnItem from 'public/src/pages/common/add-on/index.js'
import { Icon } from '@shein-aidc/icon-vue2'
import CartItemPricePopover from './PricePopover.vue'
import EvoluTag from './EvoluTag.vue'
import SFSTag from './SFSTag.vue'
import { getQuickAddCartInstance, canUseNewQuickAdd } from 'public/src/pages/components/product/quick_add_v3/common'
import { PricePlugin } from '@shein-aidc/bs-quick-add-cart-standard-mobile-vue2'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'
import { computed } from 'vue'

daEventCenter.addSubscriber({ modulecode: '1-8-4' })

export default {
  setup() {
    const { state, getters, commit, dispatch } = useStore()
    
    return {
      language: computed(() => state.language),
      appendageDrawer: computed(() => state.appendageDrawer),
      showQuickShipTag: computed(() =>getters['showQuickShipTag']),
      CartOrderSFSVisual: computed(() => getters['CartOrderSFSVisual']),
      isNewCartrowVision: computed(() => getters['isNewCartrowVision']),
      resetAppendageDrawerStatus: (payload) => commit('resetAppendageDrawerStatus', payload),
      fetchCartIndex: (payload) => dispatch('fetchCartIndex', payload),
    }
  },
  name: 'PromotionProductItem',
  // 组件注册
  components: {
    PromotionImg,
    ActTag,
    QuickShipLabel,
    Icon,
    CartItemPricePopover,
    EvoluTag,
    SFSTag,
  },
  // 组件传参
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    promotionItem: {
      type: Object,
      default(){
        return {}
      }
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    fulfillStatus: {
      type: Number,
      default: -1
    },
    index: {
      type: Number,
      default: -1
    },
    disabledTip: {
      type: String,
      default: ''
    },
    promotionType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showPop: false,
    }
  },
  // 计算属性
  computed: {
    isSoldOut() {
      return [2, 3].includes(+this.item.on_sale_status) || this.item.stock_status == '2'
    },
    showQuickShip(){
      return this.showQuickShipTag && this.item.skc_quick_ship ? true : false && this.quickShipLabel.tag_val_name_lang
    },
    promotionProductType(){
      return [13, 22].includes(+this.promotionItem.type_id) ? '2' : '1'
    },
    evoluTag() {
      return this.item.productTags?.find(tag => tag.type === 'evoluShein')
    },
    isPicked() {
      if(this.appendageDrawer.fromType.includes('addItem')) return false
      return this.promotionItem.isPicked == '1'
    },
    commonAnalysis() {
      return {
        item: {
          ...this.item,
          productRelationID: this.item.productRelationID,
          salePrice: this.item.sale_price,
          retailPrice: this.item.retail_price,
          quickship: this.showQuickShip,
          showEvoluShein: this.evoluTag,
        }, 
        index: this.index,
        sa: {
          activity_from: 'cart_pick_add'
        },
        behaviorText: this.actTag.appTraceInfo,
        is_member_gift: this.isClubGift ? 1 : 0
      }
    },
    isSuggested() {
      if (typeof window === 'undefined') return false
      return window.gbCommonInfo.IS_SUGGESTED
    },
    // 是否是付费会员赠品
    isClubGift(){
      return this.promotionType === 'clubGift'
    },
    priceContentClasses() {
      return {
        'is-column': this.item.priceData.priceMode === 'suggestV2',
      }
    },
    isShowSavePrice() {
      return !this.isSuggested && this.item.discount_price && this.item.discount_price.amount > 0
    },
    actTag() {
      return this.item.actTag || {}
    },
    isShowActTag() {
      return !this.isSoldOut && this.actTag.actionDataTagShow === '1' && this.actTag.hasAvailableTag === '1'
    },
    quickShipLabel() {
      return this.item?.productInfoLabels?.quickShipLabel || {}
    },
    originalPrice() {
      return this.item.priceData?.originalPrice
    },
    lowestPrice() {
      return this.item.priceData?.lowestPrice
    },
    unitPrice() {
      return this.item.priceData?.unitPrice
    },
    showSfsTag() {
      return this.CartOrderSFSVisual.visual_tabs && this.item?.sfs_flag == 1
    },
    // 是否展示评价
    isShowComment() {
      return this.item.comment_num > 0 && this.item.comment_rank_average > 0 && this.item.comment_num_show && this.item.comment_num_show != 0
    }
  },
  // 方法实现
  methods: {
    async getImageRatio() {
      return (await getImageRatio(this.item.goods_img)) || '3-4'
    },
    handleBtnClick(){
      if (window.__cart_item_price_popover__?.showPop) {
        window.__cart_item_price_popover__.showPop = false
      }
      if (this.isDisabled && this.disabledTip) {
        return this.$toast({ htmlString: this.disabledTip })
      }
    },
    async handleOpenQuickAdd () {
      if (window.__cart_item_price_popover__?.showPop) {
        window.__cart_item_price_popover__.showPop = false
      }
      let item = this.item
      if(this.isSoldOut || this.isDisabled) return
      
      const imgRatio = await this.getImageRatio(item)
      if (canUseNewQuickAdd('cart')) {
        getQuickAddCartInstance().open(
          {
            goods_id: item.goods_id,
            mallCode: item.mall_code || '',
          },
          {
            plugins: [PricePlugin(item.sale_price.amountWithSymbol)],
            excludePlugins: ['syncCartInfo'],
            analysisConfig: {
              index: this.index,
              code: [13, 22].includes(+this.promotionItem.type_id) ? 'cartAddBuys' : 'cartFullGifts',
              from: this.isClubGift ? 'cart_membergift_pick_add' : 'cart_pick_add',
              promotion_id: this.promotionItem.promotion_id,
              promotion_type: this.promotionItem.type_id,
              promotion_state: this.fulfillStatus
            },
            extendedParam: {
              imgRatio
            },
            featureConfig: {
              needMainSalesAttr: false,
              needLockMall: true,
              needClickToDetail: false,
            },
            callbacks: {
              onAddCartComplete: (data) => {
                if (data?.isSuccess) {
                  this.onRefreshCartData({ data: { code: '0' } })
                }
              }
            }
          }
        )
      } else {
        this.$quickAdd.open({
          goods_id: item.goods_id,
          index: this.index,
          imgRatio,
          specialPrice: item.sale_price,
          isShowColor: false,
          mallCode: item.mall_code || '',
          lockmall: true,
          hidePromotionInfo: true,
          isClickToDetail: false, // 商详跳转
          clickCallBack: {
            complete: this.onRefreshCartData
          },
          analysisConfig: {
          // sourceTarget: target,
            code: [13, 22].includes(+this.promotionItem.type_id) ? 'cartAddBuys' : 'cartFullGifts',
            from: this.isClubGift ? 'cart_membergift_pick_add' : 'cart_pick_add',
            promotion_id: this.promotionItem.promotion_id,
            promotion_type: this.promotionItem.type_id,
            promotion_state: this.fulfillStatus
          },
          // 不展示一键购
          showOneClickPay: false,
        })
      }

    },
    async onRefreshCartData ({ data }) {

      if (data?.code == '0') {
        await this.fetchCartIndex()

        this.resetAppendageDrawerStatus()
        AddOnItem?.close()
        this.$nextTick(() => {
          scrollToCartItem({ id: data.cart?.id, smooth: true })
        })
      }
    },
    GB_SHEIN_counting_star (num, size) {
      if (typeof window === 'undefined') return ''
      return window.GB_SHEIN_counting_star(num, size)
    },
  },
}
</script>

<style lang='less' scoped>
.cart-promotion__list-item{
  margin-bottom: 40/75rem;
  display: flex;
  .list-item__img{
    width: 192/75rem;
    position: relative;
    display: inline-block;
  }
  .cart-item__free{
    background: #FA6338;
    color: #FFF;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 8/75rem 16/75rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    &.cart-item__free_club{
      background: rgba(135, 60, 0, 0.80);
    }
  }
}
.cart-promotion__item-right {
  width: calc(100% - 2.56rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 24/75rem;
  position: relative;
}

.cart-promotion__item-footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart-promotion__item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 0.3867rem;
  color: @sui_color_gray_dark2;
}
.cart-promotion__item-price {
  margin-top: 16/75rem;
  line-height: 0.4534rem;
  display: inline-flex;
  align-items: center;
  margin-bottom: 2px;
  max-width: 100%;
  overflow: hidden;
  .price-amount {
    font-size: 14px;
    font-weight: bold;
    vertical-align: top;
    white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    max-width: 100%;
    word-break: break-all;
    color: var(--color);
  }
  &.is-column {
    flex-direction: column;
    align-items: flex-start;
  }
  .price-content {
    display: inline-flex;
    overflow: hidden;
    max-width: 100%;
  }
  .price-sale {
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    margin-right: 4/75rem;
  }
  .original-del {
    flex: 1;
    overflow: hidden;
    display: flex;
    margin-left: 4/75rem;
    color: @sui_color_gray_dark3;
    font-size: 10px;
    del {
      flex: 1;
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: inherit;
      margin-right: 4/75rem;
    }
  }
  .del-wrap {
    display: flex;
    flex: 1;
    max-width: 100%;
    overflow: hidden;
    align-items: baseline;
  }
  .del {
    font-size: 10px;
    color: @sui_color_gray_light1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    max-width: 100%;
  }
  .save-amount{
    margin-left: 4/75rem;
    display: inline-flex;
    padding: 2/75rem 4/75rem;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border: 0.5px solid #FA6338;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #FA6338;
    height: 24/75rem;
    .save-amount-icon{
      margin-right: 4/75rem;
    }
  }
}

.promotion-quick-ship{
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin-top: 12/75rem;
  line-height: normal;
  &_disabled{
    opacity: 0.4;
  }
  .promotion-quick-ship__label{
    margin-top: 4/75rem;
    margin-right: 8/75rem;
    .font-dpr(20px);
  }
}

.cart-promotion__item-comments {
  line-height: 0;
  white-space: nowrap;
  &.cart-promotion__item-comments_hide{
    opacity: 0;
  }
  .stars {
    vertical-align: middle;
  }
  /deep/ [class*="iconfont"] {
    font-size: 15px;
    margin-right: 0.0267rem;
    color: @sui_color_honor;
  }
  .num {
    font-size: 12px;
    line-height: 0.4rem;
    color: #999;
    vertical-align: middle;
    padding-left: 0.08rem;
  }
}
.cart-promotion__behavior {
  margin-top: 12/75rem;
}
.pick-btn{
  position: relative;
  display: flex;
  min-width: 112/75rem;
  max-width: 214/75rem;
  padding: 10/75rem 24/75rem;
  justify-content: center;
  align-items: center;
  gap: 16/75rem;
  border-radius: 24/75rem;
  background: linear-gradient(90deg, #EB4521 0%, #FF6C23 100%), #000;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color:@sui_color_white;
  margin-left: 0.32rem;
  /* rw:begin */
  background: #F82854;
  &.pick-btn-disabled{
    opacity: 0.4;
  }
  .pick-btn-text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:before{
    content: '';
    position: absolute;
    top: -20/75rem;
    right: -24/75rem;
    bottom: -20/75rem;
    left: -64/75rem;
  }
}
.cart-item__img-sold-out{
  opacity: 0.4;
}
.cart-item__stock-out {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, .6);
  padding: 8/75rem 16/75rem;
  line-height: 1;
  color: #fff;
  font-size: 10px;
  width: 100%;
  text-align: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
</style>
