<template>
  <s-drawer
    size="90%"
    max-size="90%"
    :visible="isShow"
    class="cart-promotion__drawer"
    append-to-body
    @update:visible="handleClosePopup"
    @closed="handleClosed"
  >
    <PromotionHeader 
      :title="attachmentInfo.attachmentChooseTips"
      :backgroundImages="isSheinClub ? backgroundImages : null"
      :background-size="isSheinClub ? '100% 1.52rem' : 'cover'"
      :promotion-type="isSheinClub ? 'clubGift' : ''"
      @close="handleClosePopup"
    />
    <div class="cart-promotion__wrapper">
      <GearTab
        v-if="tabs.length > 1"
        :model-value="activeIndex"
        :tabs="tabs"
        :type-id="typeId"
        :fulfill-status="fulfillStatus"
        :promotion-id="promotionId"
        @change="switchTab"
      />
      <AlertTips
        v-if="activeIndex > -1"
        :text="currentTabItem.tips"
        :promotion-type-id="typeId"
        :show-add-btn="notSatisfied"
        :class="{'alert-wrapper-bg': !tabs.length}"
        :promotion-id="promotionId"
        :level="currentTabItem.range"
        :popup-info-id="promotionItem.popupInfoId"
      />
      <ul
        v-show="!firstLoad"
        ref="productList"
        v-infinite-scroll="handleInfiniteScroll"
        :class="['j-promotion-product__list', 'promotion-product__list', {'no-tabs': tabs.length <= 1}]"
        infinite-scroll-disabled="disabledScroll"
        infinite-scroll-nodata="false"
        infinite-scroll-distance="10"
      >
        <PromotionProductItem
          v-for="(item, index) in activedItem.list"
          :key="'promotion-list-item-' + index + '-' + item.goods_id"
          :item="item"
          :promotion-item="promotionItem"
          :is-disabled="notSatisfied"
          :fulfill-status="fulfillStatus"
          :index="index"
          :disabled-tip="currentTabItem.tips"
          :promotion-type="isSheinClub ? 'clubGift' : ''"
        />
        <s-loading
          v-if="!disabledScroll"
          :show="activedItem.loading"
        />
      </ul>
      <Skeleton v-show="firstLoad && isOnline" />
      <NoNetworkEmpty
        :is-empty="!activedItem.list || (activedItem.list && activedItem.list.length == 0)"
        :language="language"
        @online-changed="handleOnlineStatus"
      />
    </div>
  </s-drawer>
</template>
<script>
import { getGiftProductList } from '../../utils/fetcher'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import PromotionHeader from './components/Header.vue'
import GearTab from './components/GearTab.vue'
import AlertTips from './components/AlertTips.vue'
import PromotionProductItem from './components/PromotionProductItem.vue'
import Skeleton from './components/Skeleton.vue'
import NoNetworkEmpty from 'public/src/pages/cart_v2/components/offline/NoNetworkEmpty.vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'
import { computed } from 'vue'

daEventCenter.addSubscriber({ modulecode: '1-8-9' })
export default {
  setup() {
    const { state, commit } = useStore()

    return {
      language: computed(() => state.language),
      appendageDrawer: computed(() => state.appendageDrawer),
      promotionState: computed(() => state.promotionState),
      resetAppendageDrawerStatus: (payload) => commit('resetAppendageDrawerStatus', payload),
      updateState: (payload) => commit('updateState', payload),
    }
  },
  name: 'FullGiftDrawerV2',
  components: {
    PromotionHeader,
    GearTab,
    AlertTips,
    PromotionProductItem,
    NoNetworkEmpty,
    Skeleton
  },
  data() {
    return {
      activeIndex: 0,
      isLoaded: false,
      listArray: [],
      isOnline: true,
      firstLoad: true,
      backgroundImages: {
        sh: 'url("https://img.ltwebstatic.com/images3_ccc/2024/05/14/0c/171567354109d703352b20d2e7912af9e07c3defd3.png")',
        rw: 'url("https://img.ltwebstatic.com/images3_ccc/2024/05/14/0c/171567354109d703352b20d2e7912af9e07c3defd3.png")'
      }, 
    }
  },
  // 
  computed: {
    isShow () {
      return this.isLoaded && this.appendageDrawer?.fullGiftShow
    },
    promotionId(){
      return this.appendageDrawer.promotionId || ''
    },
    promotionItem() {
      return this.promotionState?.integrityPromotionInfo?.[this.promotionId]?.data || {}
    },
    attachmentInfo() {
      return this.promotionItem?.promotionPopupInfo?.attachmentInfo || {}
    },
    tabs() {
      return (this.attachmentInfo?.attachmentChooseHeadList || []).map(v => ({
        ...v,
        id: v.range - 1,
        isSatisfied: this.currentRange >= v.range,
      }))
    },
    currentRange() { // 当前达到的档位
      return this.promotionItem?.range
    },
    currentTabItem() {
      return this.tabs?.find(v => v.id == this.activeIndex) || {}
    },
    fulfillStatus() {
      if (this.currentRange == this.listArray.length) {
        return 1 // 全满足
      } else if (this.currentRange > 0) {
        return 2 // 部分满足
      }
      return 0 // 不满足
    },
    typeId() {
      return this.promotionItem.type_id || ''
    },
    activedItem() { // 当前点击的档位
      return this.listArray.find(item => item.index == this.activeIndex) || {}
    },
    notSatisfied() {
      return this.activedItem.range > this.currentRange
    },
    disabledScroll() {
      return this.activedItem.complete ? true : false
    },
    /**
     * wiki.pageId=935214792
     * 当有已满足门槛的档次时，从购物车跳转至赠品列表，优先展示的是已满足且有可售赠品档次中的门槛最高的档次
     * */
    currentIndex() {
      let reached = -1
      let range = this.currentRange
      if (this.fulfillStatus !== 0) { // 满足满赠条件
        while (range && (!(this.listArray[range - 1].list?.find(item => item.on_sale_status == '1')))) { // 有可售附属品档次中的门槛最高的档次
          range--
        }
        reached = range ? range - 1 : this.currentRange - 1
      } else { // 所有档次都未满足
        let ruleIndex = 0
        while (this.listArray?.[ruleIndex]?.list && !(this.listArray?.[ruleIndex]?.list?.find(item => item.on_sale_status == '1')) ){ // 有可售附属品档次中的门槛最低的档次
          ruleIndex++
        }
        reached = ruleIndex < this.listArray.length ? ruleIndex : 0
      }
      return reached
    },
    isSheinClub() {
      return [8, 14].includes(+this.promotionItem.promotion_logo_type)
    },
  },
  watch: {
    isShow (n) {
      if (n) {
        this.handleShow()
      }
    },
  },
  mounted () {
    this.updateState({
      key: 'loadingShow',
      value: false
    })
    this.isLoaded = true
  },
  methods: {
    switchTab (id) {
      this.activeIndex = id
      this.backToTop()
    },
    backToTop() {
      this.$nextTick(() => {
        document.querySelector('.promotion-product__list')?.scrollTo({ top: 0 })
      })
    },
    async handleShow () {
      this.setDefaultData()
      await this.init()
      // 默认展示最高档
      this.$nextTick(() => {
        if(this.appendageDrawer.fromType == 'addItem-auto'){
          this.$toast(this.attachmentInfo?.autoWakeUpToastTips, 2500)
        }
        this.activeIndex = this.currentIndex
        if(this.listArray.length == 1){
          daEventCenter.triggerNotice({
            daId: '1-8-9-1',
            extraData: {
              promotion_typeid: this.typeId,
              promotion_code: this.promotionId,
              is_satisfied: this.notSatisfied ? 0 : 1,
              promotion_state: this.fulfillStatus,
              level: 1
            }
          })
        }
      })
    },
    /**
     * 初始化
     */
    async init () {
      let promiseList = []
      this.listArray.forEach((item) => {
        promiseList.push(this.reFetch({ tabIndex: item.index }))
      })
      await Promise.all(promiseList)
      this.firstLoad = false
    },
    /**
     * 设置默认属性 listArray
     */
    async setDefaultData () {
      let lists = []
      if (!this.promotionItem) return 

      const createDefaultList = ({ index, delivery_id, range }) => {
        return {
          total: 0, // 总数量
          page: 1, // 页数
          list: [], // 商品
          loading: false,   // 是否正在请求
          index, // 活动多档key
          complete: false,  // 是否全部加载完毕
          delivery_id,
          range,
        }
      }

      let rules = this.attachmentInfo.attachmentChooseHeadList || []
      if(rules?.length) {
        rules.forEach((item, index) => {
          lists.push(createDefaultList({ 
            index, 
            delivery_id: item.delivery_id,
            range: item.range,
          }))
        })
      }
      this.listArray = lists
    },
    /**
     * 关闭
     */
    handleClosePopup () {
      daEventCenter.triggerNotice({
        daId: '1-8-9-5',
        extraData: {
          promotion_typeid: this.typeId,
          promotion_code: this.promotionId,
        }
      })
      this.resetAppendageDrawerStatus()
    },
    /**
     * 发送请求
     * @param {Number} tabIndex 请求的列表index
     * @param {Boolean} loadMore 加载更多
     */
    async reFetch ({ tabIndex = 0, loadMore = false } = {}) {
      const target = this.listArray.find(item => item.index == tabIndex)
      if (!target || (target && target.loading)) return

      if (loadMore) {
        if (target.complete) return
        target.page++
      }

      target.loading = true
      
      const handleListReady = list => {
        target.loading = false
        if (loadMore) {
          target.list = target.list.concat(list)
        } else {
          target.list = list
        }
        
      }

      const list = await this.fetchData({ tabIndex, page: target.page, delivery_id: target.delivery_id })
      list && handleListReady(list)
    },
    /**
     * 列表请求
     */
    async fetchData ({ tabIndex = 0, limit = 20, page = 1, delivery_id }) {
      const requestData = {
        promotion_id: this.promotionId,
        range: tabIndex + 1,
        limit,
        page,
        ...(delivery_id ? { delivery_id } : {})
      }
      try {
        let res = await getGiftProductList(requestData)

        if (res.code == 0 && res.info && res.info.list) {
          const resInfo = res.info
          const { list = [], total = 0 } = resInfo
          // 达到最后一页
          const target = this.listArray[tabIndex]
          const listTotal = list.length + (target.list && target.list.length || 0)
          if (listTotal >= +total) target.complete = true
         
          target.total = total
          return Promise.resolve(list)
        } else {
          return Promise.reject(res)
        }
      } catch (e) {
        return Promise.reject(e)
      }
    },
    /**
     * 滚动到底
     */
    handleInfiniteScroll () {
      this.reFetch({ loadMore: true, tabIndex: this.activeIndex })
    },
    handleClosed() {
      this.reset()
    },
    reset() {
      this.listArray = []
      this.firstLoad = true
      this.activeIndex = -1
      this.resetAppendageDrawerStatus(true)
    },
    handleOnlineStatus(val) {
      this.isOnline = val
    },
  }
}
</script>
<style lang="less" scoped>
.cart-promotion__drawer{
  /deep/ .S-drawer__container{
    border-radius: 24/75rem 24/75rem 0 0;
  }
  /deep/ .S-drawer__body{
    overflow: hidden;
    height: 100%;
  }
}
.cart-promotion__wrapper {
  height: 100%;
  position: relative;
  top: -90/75rem;
  background: #ffffff;
  transform: translateZ(1px);
}
.promotion-product__list {
  padding: 24/75rem;
  height: calc(90% - 2.72rem);
  overflow: scroll;
  &.no-tabs{
    height: calc(90% - 1.52rem);
  }
}
</style>
