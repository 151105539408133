<template>
  <div class="cart-promotion__alert-wrapper">
    <section
      class="cart-promotion__alert-content"
      @click="handleAdd"
    >
      <img 
        v-if="[2, 4, 28].includes(+promotionTypeId)"
        :src="`${PUBLIC_CDN}/pwa_dist/images/cart/promotion/free-gift-icon-3ce2794946.png`" 
        class="alert-img-icon" 
        alt=""
      />
      <img
        v-else
        :src="`${PUBLIC_CDN}/pwa_dist/images/cart/promotion/add-on-icon-a4d941bbd2.png`" 
        class="alert-img-icon" 
        alt=""
      />
      <p
        class="alert-tip"
        v-html="text"
      ></p>
      <div
        v-if="showAddBtn"
        class="alert-add-btn"
      >
        {{ language.SHEIN_KEY_PWA_15091 }}
      </div>
    </section>
  </div>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { useCartAddItem }  from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'
import { computed } from 'vue'

const { PUBLIC_CDN } = gbCommonInfo
export default {
  setup() {
    const { state, commit } = useStore()

    const AddItem = useCartAddItem()
    return { 
      AddItem,
      language: computed(() => state.language),
      resetAppendageDrawerStatus: (payload) => commit('resetAppendageDrawerStatus', payload),
    }
  },
  name: 'AlertTips',
  // 组件传参
  props: {
    text: {
      type: String,
      default: () => {
        return ''
      }
    },
    promotionTypeId: {
      type: [Number, String],
      default: () => {
        return ''
      }
    },
    promotionId: {
      type: [Number, String],
      default: () => {
        return 0
      }
    },
    popupInfoId: {
      type: [Number, String],
      default: () => {
        return 0
      }
    },
    showAddBtn: {
      type: Boolean,
      default: false
    },
    level: {
      type: [Number, String],
      default: () => {
        return 0
      }
    },
  },

  data () {
    return {
      PUBLIC_CDN,
    }
  },
  watch: {
    text: {
      handler(val){
        if(!val) return
        this.$nextTick(() => {
          if(this.showAddBtn && this.promotionId) {
            daEventCenter.triggerNotice({
              daId: '1-8-9-3',
              extraData: {
                promotion_typeid: this.promotionTypeId,
                promotion_code: this.promotionId,
                level: this.level
              }
            })
          }
        })
      },
      immediate: true
    }
  },
  // 方法实现
  methods: {
    handleAdd(){
      if(!this.showAddBtn) return
      daEventCenter.triggerNotice({
        daId: '1-8-9-4',
        extraData: {
          promotion_typeid: this.promotionTypeId,
          promotion_code: this.promotionId,
          level: this.level
        }
      })
      this.resetAppendageDrawerStatus()
      this.AddItem.show({
        popupInfoId: this.popupInfoId,
        state: 'promotion_add',
      })
    }
  },
}
</script>

<style lang='less' scoped>
.alert-wrapper-bg{
  background: #ffffff;
}
.cart-promotion__alert-wrapper{
  padding: 16/75rem 24/75rem 0; 
}
.cart-promotion__alert-content{
  padding: 21/75rem 0 23/75rem 24/75rem;
  display: flex;
  align-items: center;
  border-radius: 16/75rem;
  background: linear-gradient(90deg, #FFEFDF 0%, rgba(255, 240, 225, 0.70) 14.06%, rgba(255, 244, 233, 0.20) 100%);
  /* rw:begin */
  background: linear-gradient(90deg, #FFE9EE 0%, rgba(255, 236, 241, 0.70) 20.93%, rgba(255, 247, 249, 0.20) 100%);
  .alert-img-icon {
    width: 28/75rem;
    height: 28/75rem;
  }
  .alert-tip{
    padding: 0 24/75rem 0 4/75rem;
    flex: 1;
    color: @sui_color_black_alpha80;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /deep/ span,/deep/ em{
      color: @sui_color_discount;
      font-size: 16px;
    }
  }
  .alert-add-btn{
    color: @sui_color_white;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    min-width: 112/75rem;
    padding: 10/75rem 16/75rem;
    border-radius: 48/75rem;
    line-height: 14px;
    background: linear-gradient(90deg, #EB4521 0%, #FF6C23 100%), #FA6338;
    /* rw:begin */
    background: @sui_color_brand;
  }
}
</style>
